import request from "./http"


/**
 * @description 获取评价
 *  @param {String} limit 多少条
 *  @param {String} page 第一页
 *  @param {Object} data 需查询的字段
 * @return 返回评价数据
 */
export function getEvaluate(limit, page, data) {
    return request({
        url: `/api/admin/evaluate/list?limit=${limit}&page=${page}`,
        method: "post",
        data
    })
}


/**
 * @description 新增评价
 *  @param {Object} data 参数名称
 * @return 新增成功
 */
export function saveEvaluate(data) {
    return request({
        url: `/api/admin/evaluate/save`,
        method: "post",
        data
    })
}


/**
 * @description 修改评价
 *  @param {String} id 评价id
 *  @param {Object} data 评价id
 * @return 修改成功
 */
export function updateEvaluate(id, data) {
    return request({
        url: `/api/admin/evaluate/update/${id}`,
        method: "post",
        data
    })
}


/**
 * @description 删除评价
 *  @param {String} id 评价id
 * @return 删除成功
 */
export function delEvaluate(id) {
    return request({
        url: `/api/admin/evaluate/delete/${id}`,
        method: "post",
    })
}
