<!--
* @FileDescription: 评价管理
* @Author: chenz
* @Date: 2021/4/28
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="evaluate-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      :isAdd="false"
      @_add="_add"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      :isButtons="false"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
    </CommonTable>
    <!-- 弹框表单 -->
    <CommonDialogForm
      :title="dialogTitle"
      :visible="visible"
      :form="form"
      ref="form"
      :rules="rules"
      :formItemList="formItemList"
      @_cancel="_cancel"
      @_confirm="_confirm"
      @_Close="_Close"
    >
    </CommonDialogForm>
  </div>
</template>

<script>
// 引入侧边栏json
const formItemList = require("./formItemList")

// 引入接口
import {
  getEvaluate,
  saveEvaluate,
  updateEvaluate,
  delEvaluate,
} from "../../api/evaluate-manage"

import { getProjectOptions, getStoreOptions } from "../../api/select-options"

// 引入方法
import { getExcelLabelOrProp } from "../../utils/tool"

export default {
  name: "EvaluateManage",

  data() {
    return {
      // 查询表单
      queryForm: {
        storeId: "",
        projectId: "",
      },

      // 查询表单项
      queryFormItemList: [
        // {
        //   type: "select",
        //   prop: "storeId",
        //   placeholder: "请输入要查询的店铺名称",
        //   options: [],
        // },
        // {
        //   type: "select",
        //   prop: "projectId",
        //   placeholder: "请输入要查询的项目名称",
        //   options: [],
        // },
      ],

      storeOptions: [], // 店铺下拉框
      projectOptions: [], // 项目下拉框

      // 列接受的参数
      columns: [
        { label: "用户名", prop: "userName", width: 120 },
        { label: "技师名", prop: "massagistName", width: 120 },
        { label: "门店名称", prop: "storeName", width: 120 },
        { label: "评价等级", prop: "level", width: 120 },
        { label: "评价图片", prop: "image", width: 120 },
        { label: "评价内容", prop: "content", width: 120 },
        { label: "描述相符", prop: "describeLevel" },
        { label: "门店服务", prop: "serviceLevel" },
        { label: "服务态度", prop: "attitudeLevel" },
        { label: "评论时间", prop: "createTime" },
      ],

      // 具体数据
      tableData: [],

      // 选择的表格数据
      selectChangeTableData: [],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      // 加载
      loading: false,

      // 弹框标题
      dialogTitle: "添加用户",

      // 弹框显示
      visible: false,

      // 表单
      form: {
        userName: "",
        massagistName: "",
        storeName: "",
        level: "",
        describeLevel: "",
        images: "",
        evaluateContent: "",
        content: "",
        serviceLevel: "",
        attitudeLevel: "",
        createTime: "",
      },

      // 校验
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "change" },
        ],
        massagistName: [
          { required: true, message: "技师名不能为空", trigger: "change" },
        ],
        shopName: [
          { required: true, message: "门店名称不能为空", trigger: "change" },
        ],
        level: [
          { required: true, message: "评价等级不能为空", trigger: "change" },
        ],
        evaluatePhoto: [
          { required: true, message: "评价图片不能为空", trigger: "change" },
        ],
        content: [
          { required: true, message: "评价内容不能为空", trigger: "change" },
        ],
        describeLevel: [
          { required: true, message: "描述相符不能为空", trigger: "change" },
        ],
        serviceLevel: [
          { required: true, message: "门店服务不能为空", trigger: "change" },
        ],
        attitudeLevel: [
          { required: true, message: "服务态度不能为空", trigger: "change" },
        ],
        createTime: [
          { required: true, message: "评论时间不能为空", trigger: "change" },
        ],
      },

      // 表单数据
      formItemList: formItemList,
    }
  },

  mounted() {
    // this.getProjectOptions()
    // this.getStoreOptions()
    this.getData()
  },

  methods: {
    _edit(row, table) {
      console.log(1111, row, table)
    },

    // 查询
    _query(form) {
      this.loading = true
      this.getData()
    },

    // 添加
    _add() {
      this.dialogTitle = "添加评价"
      this.visible = true
      for (const key in this.form) {
        this.form[key] = ""
      }
      this.$nextTick(() => {
        this.$refs.form.$refs.form.clearValidate() // 移除表单校验
      })
    },

    // 导出
    _export(form) {
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "评价管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 多选事件
    _handleSelectionChange(val) {
      this.selectChangeTableData = val
    },

    // 修改
    _edit(row) {
      console.log("修改", row)
      this.dialogTitle = "修改评价"
      this.visible = true
      this.form = row
    },

    // 删除
    async _del(row) {
      console.log("删除", row)
      try {
      } catch (error) {}
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 取消
    _cancel() {
      this.closeForm()
    },

    // 确定
    _confirm() {
      console.log(this.form)
      this.$refs.form.$refs.form.validate((valid) => {
        if (valid) {
          console.log("submit!")
          if (this.dialogTitle === "添加评价") {
            this.add()
          }
          if (this.dialogTitle === "修改评价") {
            this.edit()
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },

    async add() {
      const res = await saveEvaluate(this.form)
      if (res.code === 200) {
        this.visible = false
        this.$message({
          type: "success",
          message: "添加成功",
        })
        this.getData()
      }
    },

    async edit() {
      const res = await updateEvaluate(this.form.id, this.form)
      if (res.code === 200) {
        this.visible = false
        this.$message({
          type: "success",
          message: "修改成功",
        })
        this.getData()
      }
    },

    // 表单关闭按钮
    _Close() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      console.log(this.form)
      this.$refs.form.$refs.form.resetFields() // 重置表单
      this.$refs.form.$refs.form.clearValidate() // 移除表单校验
      this.visible = false // 关闭弹框
    },

    // 获取数据
    async getData() {
      this.loading = true
      const { limit, page } = this.pagingOptions

      try {
        const res = await getEvaluate(limit, page, {
          storeId: this.queryForm.storeId,
          projectId: this.queryForm.projectId,
        })
        this.loading = false
        // this.tableData = res.data.userEvaluateList
        this.tableData = res.data.list
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },

    // 获取项目下拉框
    async getProjectOptions() {
      try {
        const res = await getProjectOptions()
        this.queryFormItemList[0].options = res.data
        console.log(this.queryFormItemList)
      } catch (error) {
        return this.$message({
          type: "error",
          message: "获取项目失败",
        })
      }
    },

    // 获取店铺下拉框
    async getStoreOptions() {
      try {
        const res = await getStoreOptions()
        this.queryFormItemList[1].options = res.data.records
        console.log(this.queryFormItemList)
      } catch (error) {
        return this.$message({
          type: "error",
          message: "获取项目失败",
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.evaluate-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>